@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.home {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    object-fit: cover;
}

.holder-logo img {
    height: auto;
    width: 25vw;
}

@media screen and (max-width: 1200px){
    .holder-logo img {
        width: 30vw;
    }
}

@media screen and (max-width: 800px){
    .holder-logo img {
        width: 70vw;
    }
}

.holder-insta {
    position: absolute;
    bottom: 6em;
    right: 6em;
    z-index: 11;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media screen and (max-width: 800px){
    .holder-insta {
        position: absolute;
        bottom: 6em;
        right: 50%;
        transform: translateX(50%);
        z-index: 11;
    }
}


.holder-insta h1 {
    color: white;
    font-size: 24px;
    padding-right: 0.5em;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
}

@media screen and (max-width: 800px){
    .holder-insta h1 {
        display: none;
    }
}

.holder-insta svg {
    color: white;
    font-size: 42px;
}

@media screen and (max-width: 800px){
    .holder-insta svg {
        font-size: 52px;
    }
}